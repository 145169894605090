.toggler {
  // background-color: #f1f1f1;
  width: 180px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  // flex-direction: column;
  margin-left: 20px;
  margin-bottom: 10px;

  .word {
    font-family: "Open Sans", sans-serif;
    color: var(--mainColor);
    margin-right: 10px;
    font-size: 16px;
  }
}

.toggler-settings {
  width: 50%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  // flex-direction: column;
  margin-left: 20px;
  margin-bottom: 10px;

  .word {
    font-family: "Open Sans", sans-serif;
    color: var(--mainColor);
    margin-right: 10px;
    font-size: 16px;
  }
}

/* The switch - the box around the slider */
.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 30px;
}

/* Hide default HTML checkbox */
.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

/* The slider */
.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 22px;
  width: 22px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

input:checked + .slider {
  background-color: var(--mainColor);
}

input:focus + .slider {
  box-shadow: 0 0 1px var(--mainColor);
}

input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

.main-image-label {
  margin-top: 15px;
  border: 1px solid #707070;
  border-radius: 7px;
  width: 250px;
  height: 150px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;

  .uploaded-img {
    height: 100%;
    object-fit: contain;
    border-radius: 7px;
  }
  .main-input-image {
    display: none;
  }
  .main-label-image {
    z-index: 3;
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
    font-size: 20px;
    color: var(--secondaryColor);
    align-items: center;
    justify-content: center;
    cursor: pointer;
    background-color: #00000040;
    border-radius: 7px;
  }
}

.add-product {
  position: relative;
  margin: 20px;
  border-radius: 15px;
  box-shadow: 1px 1px 5px 1px rgba(0, 0, 0, 0.51);
  padding: 20px 100px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .header {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    h3 {
      font-family: "Open Sans", sans-serif;
      font-size: 30px;
      font-weight: bold;
      color: var(--mainColor);
      margin-bottom: 5px;
      padding: 20px;
      text-align: center;
    }
    .err {
      color: #842029;
      background-color: #f8d7da;
      border-color: #f5c2c7;
      padding: 0px 10px;
      border-radius: 5px;
      font: {
        size: 14px;
        family: "Noto Sans Arabic", sans-serif;
        weight: bold;
      }
      margin-bottom: 10px;
      text-align: center;
    }
  }

  .main-image-label {
    width: 75%;
    margin-bottom: 15px;
  }

  .form-grid {
    width: 75%;

    .input-grid {
      .main-input {
        width: 100%;
        margin: 10px 0;
      }
    }

    .button-grid {
      display: flex;

      .main-button-admin {
        width: 50%;
      }

      .main-button-voucher {
        width: 100%;
        height: 50px;
        background-color: var(--mainColor);
        color: var(--secondaryColor);
        border: 2px solid var(--secondaryColor);
        border-radius: 15px;
        margin: 10px 0;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: row;
        font: {
          family: "Open Sans", sans-serif;
          size: 20px;
        }
        cursor: pointer;
        transition: all 0.4s;

        &:hover {
          background-color: var(--secondaryColor);
          color: var(--mainColor);
          border: 1px solid var(--mainColor);
        }
      }
    }
  }

  .main-image-label.vendor-image {
    // margin-top: 15px;
    border: 1px solid #707070;
    border-radius: 50%;
    width: 250px;
    height: 250px;
    position: absolute;
    top: 12%;
    left: 16%;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    z-index: 5;

    .uploaded-img {
      height: 100%;
      object-fit: contain;
      border-radius: 7px;
    }
    .main-input-image {
      display: none;
    }
    .main-label-image {
      z-index: 3;
      position: absolute;
      width: 100%;
      height: 100%;
      display: flex;
      font-size: 20px;
      color: var(--secondaryColor);
      align-items: center;
      justify-content: center;
      cursor: pointer;
      background-color: #a2a2a2;
      border-radius: 7px;
    }
    .main-label-image-uploaded {
      z-index: 3;
      position: absolute;
      width: 100%;
      height: 100%;
      display: flex;
      font-size: 20px;
      color: var(--secondaryColor);
      align-items: center;
      justify-content: center;
      cursor: pointer;
      background-color: #00000040;
      border-radius: 7px;
    }
  }
  .main-image-label.vendor-cover {
    margin-top: 15px;
    margin-bottom: 12%;
    border: 1px solid #707070;
    border-radius: 7px;
    width: 90%;
    height: 250px;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;

    .uploaded-img {
      height: 100%;
      object-fit: contain;
      border-radius: 7px;
    }
    .main-input-image {
      display: none;
    }
    .main-label-image {
      z-index: 3;
      position: absolute;
      width: 100%;
      height: 100%;
      display: flex;
      font-size: 20px;
      color: var(--secondaryColor);
      align-items: center;
      justify-content: center;
      cursor: pointer;
      background-color: #00000040;
      border-radius: 7px;
    }
  }
}
