.input-label-disabled {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;

  .disabled-label {
    color: var(--mainColor);
    font-size: 15px;
    min-width: 20%;
  }

  .input-div {
    max-width: 75%;
  }

  .disabled-input {
    max-width: 100%;
    height: 40px;
    background-color: #f1f1f1;
    border: none;
    border-radius: 15px;
    padding-left: 20px;
    padding-right: 20px;
    margin-bottom: 5px;
    font: {
      family: "Open Sans", sans-serif;
      size: 15px;
    }
    display: inline;
  }

  .input-textarea-question {
    max-width: 75%;

    .disabled-input {
      max-width: 100%;
      height: 50px;
      background-color: #f1f1f1;
      border: none;
      border-radius: 15px;
      padding-left: 20px;
      padding-right: 20px;
      padding-top: 7px;
      margin-bottom: 5px;
      resize: none;
      font: {
        family: "Open Sans", sans-serif;
        size: 15px;
      }
      display: inline;
    }
  }

  .input-textarea-answer {
    max-width: 75%;

    .disabled-input {
      max-width: 100%;
      min-height: 200px;
      background-color: #f1f1f1;
      border: none;
      border-radius: 15px;
      padding-left: 20px;
      padding-right: 20px;
      padding-top: 7px;
      margin-bottom: 5px;
      resize: none;
      font: {
        family: "Open Sans", sans-serif;
        size: 15px;
      }
      display: inline;
    }
  }
}
