.add-admin {
  position: relative;
  margin: 20px;
  border-radius: 15px;
  box-shadow: 1px 1px 5px 1px rgba(0, 0, 0, 0.51);
  padding: 20px 100px;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: auto auto;
  grid-template-areas: "header header header" "form form form";
  column-gap: 30px;

  .header {
    grid-area: header;
  }
  h3 {
    font-family: "Open Sans", sans-serif;
    font-size: 35px;
    font-weight: bold;
    color: var(--mainColor);
    margin-bottom: 15px;
    padding: 20px;
    text-align: center;
  }
  .admin-form {
    grid-area: form;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;

    .main-image-label.admin-image {
      margin-top: 15px;
      margin-bottom: 15px;
      border: 1px solid #707070;
      width: 250px;
      height: 250px;
      border-radius: 50%;
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      overflow: hidden;

      .uploaded-img {
        height: 100%;
        object-fit: contain;
        border-radius: 7px;
      }
      .main-input-image {
        display: none;
      }
      .main-label-image {
        z-index: 3;
        position: absolute;
        width: 100%;
        height: 100%;
        display: flex;
        font-size: 20px;
        color: var(--secondaryColor);
        align-items: center;
        justify-content: center;
        cursor: pointer;
        background-color: #00000040;
        border-radius: 7px;
      }
    }

    .input-grid {
      .main-input-label {
        position: relative;
        display: flex;

        .main-input {
          width: 100%;
          height: 50px;
          background-color: #f1f1f1;
          border: none;
          border-radius: 15px;
          padding-left: 20px;
          padding-right: 20px;
          margin-bottom: 5px;
          font: {
            family: "Open Sans", sans-serif;
            size: 16px;
          }

          &:focus {
            outline-color: var(--mainColor);
          }

          &:focus ~ .main-label,
          &:not(:placeholder-shown).main-input:not(:focus) ~ .main-label {
            top: 10px;
            background-color: var(--mainColor);
            color: var(--secondaryColor);
            font: {
              size: 13px;
              weight: 100;
            }
            padding: 2px 10px 3px;
            border-radius: 10px;
          }
        }

        .main-label {
          position: absolute;
          top: 65%;
          transform: translate(0, -100%);
          color: #86817c;
          cursor: text;
          transition: all 0.4s;
          font: {
            family: "Open Sans", sans-serif;
            size: 16px;
            weight: 100;
          }
        }
      }
    }
  }
  .button-grid {
    display: flex;
    padding: 0 10px;
    padding-bottom: 20px;
    width: 100%;
    display: flex;
    justify-content: center;

    .main-button-admin {
      height: 50px;
      width: 30%;
      background-color: var(--mainColor);
      color: var(--secondaryColor);
      border: 2px solid var(--secondaryColor);
      border-radius: 15px;
      margin: 10px 0;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: row;
      margin-right: 2px;
      margin-left: 2px;
      font: {
        family: "Open Sans", sans-serif;
        size: 20px;
      }
      cursor: pointer;
      transition: all 0.4s;

      &:hover {
        background-color: var(--secondaryColor);
        color: var(--mainColor);
        border: 1px solid var(--mainColor);
      }
    }
  }
}

.back {
  position: absolute;
  top: 20px;
  left: 20px;
  i {
    font-size: 30px;
    color: var(--mainColor);
    cursor: pointer;
  }
}
