.view {
  border: none;
  background-color: var(--mainColor);
  color: var(--secondaryColor);
  border: 1px solid var(--mainColor);
  font-size: 14px;
  cursor: pointer;
  padding: 6px 10px;
  transition: all 0.5s;
  border-radius: 8px;
  text-decoration: none;
  width: fit-content;
  margin-top: 5px;

  &:hover {
    background-color: transparent;
    border-radius: 8px;
    color: var(--mainColor);
    border: 1px solid var(--mainColor);
  }
}
