.table-header {
  width: 100%;
  text-align: center;

  tr {
    text-align: center;
    font-size: 18px;
    color: #010101;

    .table-header-data {
      text-align: center;
      padding-bottom: 20px;
      padding-top: 20px;
      padding-left: 10px;
      padding-right: 10px;
    }
  }
}
