.points-card {
  margin-bottom: 10px;
  box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.4);
  border-radius: 10px;

  .data {
    font-family: "Open Sans", sans-serif;
    width: 100%;
    padding: 30px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    h3 {
      color: black;
    }
    p {
      padding: 3px;
      color: #919090;
    }
    button {
      border: none;
      background-color: transparent;
      cursor: pointer;
      color: var(--mainColor);
    }
    span {
      color: var(--mainColor);
    }
    .vip_points {
      width: 360px;
      height: 50px;
      background-color: #f1f1f1;
      border: none;
      border-radius: 15px;
      padding-left: 20px;
      padding-right: 20px;
      margin-bottom: 5px;
      font: {
        family: "Open Sans", sans-serif;
        size: 16px;
      }

      &:focus {
        outline-color: var(--mainColor);
      }
    }
    .update-btn {
      display: flex;

      .update {
        background-color: var(--mainColor);
        border: 1px solid transparent;
        padding: 7px 15px;
        color: var(--secondaryColor);
        border-radius: 5px;
        cursor: pointer;
        transition: all 0.4s;
        margin: 2px;
        font: {
          family: "Open Sans", sans-serif;
          size: 16px;
        }

        &:hover {
          background-color: var(--secondaryColor);
          color: var(--mainColor);
          border: 1px solid var(--mainColor);
        }
      }
    }
  }
  .hidden-content {
    padding-bottom: 10px;
    p {
      font-family: "Open Sans", sans-serif;
      padding: 0 0 5px 35px;
      font-size: 15px;
    }
  }
}
