.chat-messages {
  display: flex;
  flex-direction: column;
  height: 100%;

  .chat-bar {
    width: 100%;
    height: 15%;
    background-color: var(--mainColor);
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-top-right-radius: 15px;

    .block {
      button {
        background-color: #f01212;
        width: 70px;
        height: 30px;
        border: 1px solid #f01212;
        color: white;
        font-family: "Open Sans", sans-serif;
        border-radius: 8px;
        cursor: pointer;
        margin-right: 15px;
        transition: all 0.5s;

        &:hover {
          background-color: white;
          color: #f01212;
          border: 1px solid #f01212;
        }
      }
    }
    .chat-data {
      width: 50%;
      height: 100%;
      display: flex;
      align-items: center;
      cursor: pointer;

      .image {
        width: 40px;
        height: 40px;
        border-radius: 50%;
        margin-left: 20px;
        margin-right: 10px;

        .icon-avatar {
          background-color: rgba(255, 255, 255, 0.368);
          padding: 10px;
          border-radius: 7px;
        }

        img {
          width: 100%;
          height: 100%;
          object-fit: contain;
          border-radius: 50%;
        }
      }
      .name {
        font-family: "Open Sans", sans-serif;
        p {
          text-transform: capitalize;
          color: rgb(233, 233, 233);
        }

        .time {
          font-size: 13px;
        }
      }
    }
  }

  .chat-body {
    padding: 10px;
    height: 90%;
    overflow-y: auto;

    .sender {
      margin-right: 10px;
      margin-bottom: 10px;
      display: flex;
      justify-content: flex-end;
      align-items: flex-end;
      flex-direction: column;
      .message {
        background-color: #465568;
        height: fit-content;
        max-width: 50%;
        color: #f3f5ff;
        font-family: "Open Sans", sans-serif;
        border-top-left-radius: 15px;
        border-top-right-radius: 15px;
        border-bottom-left-radius: 15px;
        padding: 8px 12px;
        text-align: left;
        font-size: 14px;
        font-weight: 100;
        white-space: pre-line;
        word-wrap: break-word;
      }

      .date {
        color: #d5e6ff;
        font-family: "Open Sans", sans-serif;
        font-size: 10px;
        text-align: right;
      }
    }

    .receiver {
      margin-left: 10px;
      margin-bottom: 10px;
      display: flex;
      justify-content: flex-start;
      align-items: flex-start;
      flex-direction: column;
      .message {
        background-color: #cdd5e2;
        height: fit-content;
        max-width: 40%;
        color: #0e1327;
        font-family: "Open Sans", sans-serif;
        border-top-right-radius: 15px;
        border-bottom-left-radius: 15px;
        border-bottom-right-radius: 15px;
        padding: 8px 12px;
        text-align: left;
        font-size: 14px;
        font-weight: 100;
        white-space: pre-line;
        word-wrap: break-word;
      }
      .date {
        color: #707070;
        font-family: "Open Sans", sans-serif;
        font-size: 10px;
        text-align: left;
      }
    }
  }

  .send-message {
    padding: 30px 0;
    height: 10%;
    border-top: 1px solid #cdd5e2;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    .message-input {
      width: 90%;
      height: 40px;
      border-radius: 5px;
      border: 1px solid var(--mainColor);
      padding: 10px;
      font-family: "Open Sans", sans-serif;
      color: #0e1327;
      resize: none;

      &:focus {
        outline: none;
      }
    }

    .send-message-icon {
      color: var(--mainColor);
      cursor: pointer;
    }

    .block-message {
      font-family: "Open Sans", sans-serif;
      color: #0e1327;
    }
  }
}
