.main-popup-card {
  margin: 20px;
  box-shadow: 1px 1px 5px 1px rgba(0, 0, 0, 0.51);
  border-radius: 15px;
  padding-bottom: 10px;

  .add-popup {
    font-family: "Open Sans", sans-serif;
    display: flex;
    justify-content: flex-end;
    padding: 20px;
    margin: 20px;

    .button {
      display: flex;
    }

    .main-button-add {
      width: 120px;
      height: 40px;
      margin-left: 5px;
      background-color: var(--mainColor);
      color: var(--secondaryColor);
      border: none;
      border-radius: 8px;
      font: {
        family: "Open Sans", sans-serif;
        size: 16px;
      }
      cursor: pointer;
      transition: all 0.4s;

      &:hover {
        background-color: var(--secondaryColor);
        color: var(--mainColor);
        border: 1px solid var(--mainColor);
      }
    }
  }

  .small-banner {
    margin-bottom: 5%;
    border-radius: 7px;
    width: 100%;
    height: 150px;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;

    .uploaded-img {
      height: 100%;
      width: 30%;
      border-radius: 7px;
      cursor: pointer;
    }

    .remove {
      border: none;
      background-color: var(--mainColor);
      color: var(--secondaryColor);
      border: 1px solid var(--mainColor);
      font-size: 20px;
      border-radius: 8px;
      cursor: pointer;
      padding: 6px 10px;
      transition: all 0.5s;
      position: absolute;
      top: 0;
      right: 0;
      margin: 20px;

      &:hover {
        background-color: transparent;
        border-radius: 8px;
        color: var(--mainColor);
        border: 1px solid var(--mainColor);
      }
    }
  }
}
