.client-image.admin-image-details {
  grid-area: image;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 10px;
  width: 250px;
  height: 250px;
  border-radius: 50%;
  border: 1px solid #707070;
  position: relative;
  overflow: hidden;
  object-fit: cover;

  img {
    height: 100%;
    border-radius: 50%;
    overflow: hidden;
    padding: 5px;
    object-fit: cover;
  }

  .delete-image {
    position: absolute;
    top: 50%;
    left: 0%;
    transform: translateY(-50%);
    width: 35px;
    height: 35px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    background-color: var(--mainColor);
    display: none;

    i {
      color: var(--secondaryColor);
      background-color: transparent;
      border: none;
      font-size: 20px;
    }
  }
}

.client-image.admin-image-details:hover .delete-image {
  display: flex;
}

.edit {
  text-align: right;
  margin-top: 10px;

  button {
    width: 10%;
    color: var(--mainColor);
    border: none;
    background-color: var(--secondaryColor);
    font: {
      family: "Open Sans", sans-serif;
      size: 18px;
    }
    cursor: pointer;
  }
}
