.user {
  grid-area: user;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5px;
  border: 1px solid var(--mainColor);
  margin: 10px 20px;
}

.all-branches {
  text-align: center;
  font-family: "Open Sans", sans-serif;
  padding: 10px;
  border-radius: 5px;
  width: 50%;

  .all-branches-btn {
    border: none;
    cursor: pointer;
    color: var(--mainColor);
    background-color: transparent;
    font-size: 12px;
  }
}
.active-btn {
  text-align: center;
  font-family: "Open Sans", sans-serif;
  background-color: var(--mainColor);
  padding: 10px;
  border-radius: 5px;
  width: 50%;

  .all-branches-btn {
    color: var(--secondaryColor);
    border: none;
    cursor: pointer;
    background-color: transparent;
    font-size: 12px;
  }
}

.add-branch {
  grid-area: add;
  margin-top: 20px;
  font-family: "Open Sans", sans-serif;
  padding: 10px;
  display: flex;
  justify-content: space-between;
  width: 100%;

  .form-search {
    margin: 0px 20px;
    width: 100%;

    .form-input-search {
      position: relative;

      .input-search {
        width: 100%;
        height: 40px;
        background-color: #e3e3e3;
        border: none;
        border-radius: 7px;
        padding-left: 60px;
        padding-right: 20px;
        font: {
          size: 16px;
          family: "Open Sans", sans-serif;
        }

        &::placeholder {
          color: #111111;
          font-size: 14px;
        }
      }

      .label-search {
        position: absolute;
        top: 50%;
        left: 0;
        margin-left: 5px;
        transform: translate(0, -50%);
        font: {
          family: "Open Sans", sans-serif;
          weight: 100;
          size: 16px;
        }
        color: #86817c;
        cursor: text;
        transition: all 0.4s;
        display: flex;
        align-items: center;

        .SearchLogo {
          width: 23px;
          margin-left: 20px;
        }
      }
    }
  }

  .button {
    display: flex;
  }

  .main-button-add {
    width: 120px;
    height: 40px;
    margin-left: 5px;
    background-color: var(--mainColor);
    color: var(--secondaryColor);
    border: none;
    border-radius: 8px;
    font: {
      family: "Open Sans", sans-serif;
      size: 16px;
    }
    cursor: pointer;
    transition: all 0.4s;

    &:hover {
      background-color: var(--secondaryColor);
      color: var(--mainColor);
      border: 1px solid var(--mainColor);
    }
  }

  .main-button-add-sub-agent {
    width: 140px;
    height: 40px;
    margin-left: 5px;
    background-color: var(--mainColor);
    color: var(--secondaryColor);
    border: none;
    border-radius: 8px;
    font: {
      family: "Open Sans", sans-serif;
      size: 16px;
    }
    cursor: pointer;
    transition: all 0.4s;

    &:hover {
      background-color: var(--secondaryColor);
      color: var(--mainColor);
      border: 1px solid var(--mainColor);
    }
  }

  .main-button-add-sub-agent-renew {
    width: auto;
    height: 40px;
    margin-left: 5px;
    padding: 0 15px;
    background-color: var(--mainColor);
    color: var(--secondaryColor);
    border: 1px solid var(--mainColor);
    border-radius: 8px;
    font: {
      family: "Open Sans", sans-serif;
      size: 16px;
    }
    cursor: pointer;
    transition: all 0.4s;

    &:hover {
      background-color: var(--secondaryColor);
      color: var(--mainColor);
      border: 1px solid var(--mainColor);
    }
  }
}
.branch-cards-container {
  grid-area: card;
  padding: 10px;
}

.request {
  border: 1px solid var(--mainColor);
  border-radius: 5px;
  background-color: var(--mainColor);
  color: var(--secondaryColor);
  font-size: 15px;
  cursor: pointer;
  padding: 6px 10px;
  transition: all 0.5s;
  margin: 8px 0;

  &:hover {
    background-color: var(--secondaryColor);
    border: 1px solid var(--mainColor);
    border-radius: 5px;
    color: var(--mainColor);
  }
}

.client-image.vendor-image-details {
  grid-area: image;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 10px;
  width: 250px;
  height: 250px;
  border-radius: 7px;
  border: 1px solid #707070;
  position: relative;
  overflow: hidden;
  object-fit: cover;
  top: 10%;

  img {
    height: 100%;
    border-radius: 7px;
    overflow: hidden;
    padding: 5px;
    object-fit: cover;
  }

  .delete-image {
    position: absolute;
    top: 0;
    left: 0;
    width: 35px;
    height: 35px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    background-color: var(--mainColor);
    display: none;

    i {
      color: var(--secondaryColor);
      background-color: transparent;
      border: none;
      font-size: 20px;
    }
  }
}

.client-image.product-image-details {
  grid-area: image;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 10px;
  width: 400px;
  height: 400px;
  border-radius: 7px;
  border: 1px solid #707070;
  position: relative;
  overflow: hidden;
  object-fit: cover;
  top: 10%;

  img {
    height: 100%;
    border-radius: 7px;
    overflow: hidden;
    padding: 5px;
    object-fit: cover;
  }

  .delete-image {
    position: absolute;
    top: 0;
    left: 0;
    width: 35px;
    height: 35px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    background-color: var(--mainColor);
    display: none;

    i {
      color: var(--secondaryColor);
      background-color: transparent;
      border: none;
      font-size: 20px;
    }
  }
}

.client-image.vendor-image-details:hover .delete-image {
  display: flex;
}

@media screen and (max-width: 1350px) {
  .branch-cards-container {
    grid-template-columns: repeat(4, 1fr);
    justify-content: center;
  }
}
@media screen and (max-width: 991px) {
  .branch-cards-container {
    grid-template-columns: repeat(2, 1fr);
    justify-content: center;
  }
}
@media screen and (max-width: 687px) {
  .branch-cards-container {
    grid-template-columns: repeat(1, 1fr);
    justify-content: center;
  }
}

.vendor-datails {
  margin: 20px;
  box-shadow: 1px 1px 5px 1px rgba(0, 0, 0, 0.51);
  border-radius: 15px;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;

  .back {
    position: absolute;
    i {
      font-size: 30px;
      color: var(--mainColor);
      cursor: pointer;
    }
  }

  .vendor-image-cover {
    position: relative;
    width: 100%;

    .main-image-label.vendor-image {
      border: 2px solid #d4d4d4;
      border-radius: 50%;
      width: 250px;
      height: 250px;
      position: absolute;
      bottom: -25%;
      left: 5%;
      overflow: hidden;
      z-index: 5;
      img {
        width: 100%;
      }

      .delete-image {
        position: absolute;
        top: 50%;
        left: 0%;
        transform: translateY(-50%);
        width: 35px;
        height: 35px;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        background-color: var(--mainColor);
        display: none;

        i {
          color: var(--secondaryColor);
          background-color: transparent;
          border: none;
          font-size: 20px;
        }
      }
    }
    .main-image-label.vendor-image:hover .delete-image {
      display: flex;
    }
    .main-image-label.vendor-cover {
      border-radius: 7px;
      width: 100%;
      height: 250px;
      overflow: hidden;
      border: none;
      margin-top: 0;
      img {
        width: 100%;
      }

      .delete-image {
        position: absolute;
        top: 0;
        left: 0;
        width: 35px;
        height: 35px;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        background-color: var(--mainColor);
        display: none;

        i {
          color: var(--secondaryColor);
          background-color: transparent;
          border: none;
          font-size: 20px;
        }
      }
    }
    .main-image-label.vendor-cover:hover .delete-image {
      display: flex;
    }
  }

  .client-datails {
    margin-top: 20px;
    height: auto;
    padding-bottom: 20px;

    .data {
      width: 100%;
      height: 100%;
      border-radius: 30px;
      padding: 20px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      font-family: "Open Sans", sans-serif;
      font-size: 18px;

      h2 {
        text-align: center;
        text-transform: uppercase;
        color: var(--mainColor);
        margin-bottom: 5px;
      }

      h3 {
        color: var(--mainColor);

        span {
          color: #919090;
        }
      }

      .toggler {
        width: 235px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin: 8px 0;

        .word {
          font-family: "Open Sans", sans-serif;
          color: var(--mainColor);
          margin-right: 10px;
          font-size: 15px;
        }
      }

      /* The switch - the box around the slider */
      .switch {
        position: relative;
        display: inline-block;
        width: 50px;
        height: 25px;
      }

      /* Hide default HTML checkbox */
      .switch input {
        opacity: 0;
        width: 0;
        height: 0;
      }

      /* The slider */
      .slider {
        position: absolute;
        cursor: pointer;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: #ccc;
        -webkit-transition: 0.4s;
        transition: 0.4s;
      }

      .slider:before {
        position: absolute;
        content: "";
        height: 15px;
        width: 15px;
        left: 8px;
        bottom: 5px;
        background-color: white;
        -webkit-transition: 0.4s;
        transition: 0.4s;
      }

      input:checked + .slider {
        background-color: var(--mainColor);
      }

      input:focus + .slider {
        box-shadow: 0 0 1px var(--mainColor);
      }

      input:checked + .slider:before {
        -webkit-transform: translateX(26px);
        -ms-transform: translateX(26px);
        transform: translateX(20px);
      }

      /* Rounded sliders */
      .slider.round {
        border-radius: 34px;
      }

      .slider.round:before {
        border-radius: 50%;
      }
    }

    .actions {
      display: flex;
      justify-content: flex-end;

      .approve {
        width: 100px;
        height: 40px;
        background-color: var(--mainColor);
        color: var(--secondaryColor);
        border: 1px solid var(--mainColor);
        border-radius: 7px;
        margin-right: 10px;
        font: {
          family: "Open Sans", sans-serif;
          size: 18px;
        }
        cursor: pointer;
        transition: all 0.4s;

        &:hover {
          background-color: var(--secondaryColor);
          color: var(--mainColor);
          border: 1px solid var(--mainColor);
        }
      }

      .decline {
        width: 100px;
        height: 40px;
        background-color: #58151c;
        color: var(--secondaryColor);
        border: none;
        border-radius: 7px;
        margin-right: 18px;
        font: {
          family: "Open Sans", sans-serif;
          size: 18px;
        }
        cursor: pointer;
        transition: all 0.4s;

        &:hover {
          background-color: var(--secondaryColor);
          color: #58151c;
          border: 1px solid #58151c;
        }
      }
    }
  }

  .user {
    width: 95%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 5px;
    border: 1px solid var(--mainColor);
    margin: 10px 20px;
  }

  .branch-cards-container {
    width: 100%;
    padding: 10px;
  }
}
