.load {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 15px;

  button {
    height: 40px;
    background-color: var(--secondaryColor);
    color: var(--mainColor);
    border: 1px solid var(--mainColor);
    border-radius: 10px;
    padding: 0px 10px;
    font: {
      family: "Open Sans", sans-serif;
      size: 16px;
    }
    cursor: pointer;
    transition: all 0.4s;

    &:hover {
      background-color: var(--mainColor);
      color: var(--secondaryColor);
      border: 1px solid var(--mainColor);
    }
  }
}
