.main-image-label.vendor-image {
  margin-top: 15px;
  border: 1px solid #707070;
  border-radius: 7px;
  width: 250px;
  height: 250px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;

  .uploaded-img {
    height: 100%;
    object-fit: contain;
    border-radius: 7px;
  }
  .main-input-image {
    display: none;
  }
  .main-label-image {
    z-index: 3;
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
    font-size: 20px;
    color: var(--secondaryColor);
    align-items: center;
    justify-content: center;
    cursor: pointer;
    background-color: #00000040;
    border-radius: 7px;
  }
}

.main-image-label.vendor-cover {
  margin-top: 15px;
  border: 1px solid #707070;
  border-radius: 7px;
  width: 90%;
  height: 250px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;

  .uploaded-img {
    height: 100%;
    object-fit: contain;
    border-radius: 7px;
  }
  .main-input-image {
    display: none;
  }
  .main-label-image {
    z-index: 3;
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
    font-size: 20px;
    color: var(--secondaryColor);
    align-items: center;
    justify-content: center;
    cursor: pointer;
    background-color: #00000040;
    border-radius: 7px;
  }
}
