@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Noto+Sans+Arabic&display=swap");

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

:root {
  --mainColor: #fc7300;

  --secondaryColor: #ffffff;
}

::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}
::-webkit-scrollbar-track {
  background: var(--secondaryColor);
  border-radius: 5px;
}
::-webkit-scrollbar-thumb {
  background: #707070;
  border-radius: 5px;
}
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.main-input-label {
  position: relative;
  display: flex;

  .main-input {
    width: 360px;
    height: 50px;
    background-color: #f1f1f1;
    border: none;
    border-radius: 15px;
    padding-left: 20px;
    padding-right: 20px;
    margin-bottom: 5px;
    font: {
      family: "Open Sans", sans-serif;
      size: 16px;
    }

    &:focus {
      outline-color: var(--mainColor);
    }

    &:focus ~ .main-label,
    &:not(:placeholder-shown).main-input:not(:focus) ~ .main-label {
      top: 0;
      background-color: var(--mainColor);
      color: var(--secondaryColor);
      font: {
        size: 13px;
        weight: 100;
      }
      padding: 2px 10px 3px;
      border-radius: 10px;
    }
  }

  .main-label {
    position: absolute;
    top: 50%;
    transform: translate(0, -50%);
    color: #86817c;
    cursor: text;
    transition: all 0.4s;
    font: {
      family: "Open Sans", sans-serif;
      size: 16px;
      weight: 100;
    }
  }
}

.main-input-label {
  position: relative;
  display: flex;
  margin-bottom: 10px;

  .main-textarea-question {
    height: 50px;
    background-color: #f1f1f1;
    border: none;
    border-radius: 15px;
    padding-left: 20px;
    padding-right: 20px;
    margin-bottom: 5px;
    padding-top: 15px;
    resize: none;
    font: {
      family: "Open Sans", sans-serif;
      size: 16px;
    }

    &:focus {
      outline-color: var(--mainColor);
    }

    &:focus ~ .main-label,
    &:not(:placeholder-shown).main-textarea-question:not(:focus) ~ .main-label {
      top: 0;
      background-color: var(--mainColor);
      color: var(--secondaryColor);
      resize: none;
      font: {
        size: 13px;
        weight: 100;
      }
      padding: 2px 10px 3px;
      border-radius: 10px;
    }
  }

  .main-textarea-answer {
    height: 200px;
    background-color: #f1f1f1;
    border: none;
    border-radius: 15px;
    padding-left: 20px;
    padding-right: 20px;
    margin-bottom: 5px;
    padding-top: 20px;
    resize: none;
    font: {
      family: "Open Sans", sans-serif;
      size: 16px;
    }

    &:focus {
      outline-color: var(--mainColor);
    }

    &:focus ~ .main-label,
    &:not(:placeholder-shown).main-textarea-answer:not(:focus) ~ .main-label {
      top: 0;
      background-color: var(--mainColor);
      color: var(--secondaryColor);
      resize: none;
      font: {
        size: 13px;
        weight: 100;
      }
      padding: 2px 10px 3px;
      border-radius: 10px;
    }
  }

  .main-label {
    position: absolute;
    top: 50%;
    transform: translate(0, -50%);
    color: #86817c;
    cursor: text;
    transition: all 0.4s;
    font: {
      family: "Open Sans", sans-serif;
      size: 16px;
      weight: 100;
    }
  }
}

.main-button {
  width: 360px;
  height: 50px;
  background-color: var(--mainColor);
  color: var(--secondaryColor);
  border: none;
  border-radius: 15px;
  font: {
    family: "Open Sans", sans-serif;
    size: 20px;
  }
  cursor: pointer;
  transition: all 0.4s;

  &:hover {
    background-color: var(--secondaryColor);
    color: var(--mainColor);
    border: 1px solid var(--mainColor);
  }
}

a {
  text-decoration: none;
}

[dir="ltr"] {
  .main-button {
    font: {
      family: "Open Sans", sans-serif;
    }
  }
  .main-input-label {
    .main-label {
      left: 0;
      margin-left: 20px;
    }
  }
}

[dir="rtl"] {
  .main-button {
    font: {
      family: "Noto Sans Arabic", sans-serif;
    }
  }
  .main-input-label {
    .main-label {
      right: 0;
      margin-right: 20px;
    }
  }
}

.search-wrapper {
  height: auto;
}
