.base-layout {
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  grid-template-rows: 70px auto;
  grid-template-areas: "nav nav nav nav nav nav nav nav nav nav nav nav" "side side body body body body body body body body body body";

  .content-container {
    grid-area: body;
  }
}

@media (max-width: 1010px) {
  .base-layout {
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    grid-template-rows: 70px auto;
    grid-template-areas: "nav nav nav nav nav nav nav nav nav nav nav nav" "body body body body body body body body body body body body";
  }
}
