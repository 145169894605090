.main-image-label.admin-image {
  margin-top: 15px;
  border: 1px solid #707070;
  border-radius: 7px;
  width: 250px;
  height: 250px;
  border-radius: 50%;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;

  .uploaded-img {
    height: 100%;
    object-fit: contain;
    border-radius: 7px;
  }
  .main-input-image {
    display: none;
  }
  .main-label-image {
    z-index: 3;
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
    font-size: 20px;
    color: var(--secondaryColor);
    align-items: center;
    justify-content: center;
    cursor: pointer;
    background-color: #00000040;
    border-radius: 7px;
  }
}
.regenerate {
  font-size: 24px;
  display: flex;

  button {
    margin: 10px;
    background-color: var(--mainColor);
    margin-top: 10px;
    width: 300px;
    border: 2px solid var(--secondaryColor);
    border-radius: 10px;
    height: 50px;
    cursor: pointer;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    transition: all 0.4s;
    color: var(--secondaryColor);
    font-size: 24px;

    &:hover {
      background-color: var(--secondaryColor);
      color: var(--mainColor);
      border: 1px solid var(--mainColor);
    }
  }
}
