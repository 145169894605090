.table-row {
  // height: 50px;
  .circle-label {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .table-data-cell {
    &.permission-cell {
      .permissions-container {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 15px;
        flex-wrap: nowrap;
      }
    }
  }

  .permission-icon {
    cursor: pointer;
    margin: 0 5px;
    &:hover {
      transform: scale(1.1);
    }
  }
}
