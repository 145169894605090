.branches {
  margin-bottom: 10px;
  p {
    font-family: "Open Sans", sans-serif;
    margin-top: 10px;
    font-size: 18px;
  }

  .toggler {
    width: 400px;
    margin-top: 5px;

    label {
      text-transform: capitalize;
    }
  }
}
