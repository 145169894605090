.mainCard {
  display: grid;
  margin: 20px;
  border-radius: 15px;
  box-shadow: 1px 1px 5px 1px rgba(0, 0, 0, 0.51);
  padding: 25px;
  display: flex;
  justify-content: space-between;

  .card-header {
    color: #707070;
    font: {
      family: "Open Sans", sans-serif;
      size: 18px;
    }
    margin-bottom: 10px;
  }

  .card-body {
    font: {
      family: "Open Sans", sans-serif;
      size: 22px;
    }
    color: #010101;
    margin-bottom: 10px;
  }

  .card-footer {
    color: #707070;
    font: {
      family: "Open Sans", sans-serif;
      size: 18px;
    }
  }

  .main-button-add {
    width: 120px;
    height: 60px;
    background-color: var(--mainColor);
    color: var(--secondaryColor);
    border: 1px solid var(--mainColor);
    border-radius: 8px;
    font: {
      family: "Open Sans", sans-serif;
      size: 16px;
    }
    cursor: pointer;
    transition: all 0.4s;

    &:hover {
      background-color: var(--secondaryColor);
      color: var(--mainColor);
      border: 1px solid var(--mainColor);
    }
  }

  .main-button-org {
    width: 120px;
    height: 50px;
    background-color: var(--mainColor);
    color: var(--secondaryColor);
    border: none;
    border-radius: 8px;
    font: {
      family: "Open Sans", sans-serif;
      size: 16px;
    }
    cursor: pointer;
    transition: all 0.4s;

    &:hover {
      background-color: var(--secondaryColor);
      color: var(--mainColor);
      border: 1px solid var(--mainColor);
    }
  }
}
@media (max-width: 768px) {
  .mainCard {
    flex-direction: column;
    align-items: center;
    text-align: center;

    .card,
    .card-holder .card {
      margin-bottom: 15px;
      margin-right: 45px;
      padding: 22px;
      box-shadow: 1px 1px 5px 1px rgba(0, 0, 0, 0.51);
      width: 100%;
      border-radius: 15px;
    }
  }
}

[dir="ltr"] {
  .mainCard {
    padding-left: 75px;
  }
}

[dir="rtl"] {
  .mainCard {
    padding-right: 75px;
  }
}
