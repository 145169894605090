.side {
  grid-area: side;
  box-shadow: 1px 5px 5px 1px rgba(0, 0, 0, 0.25);
  padding-bottom: 15px;
  height: 100vh;
  overflow: auto;
  position: fixed;
  top: 75px;
  padding-bottom: 90px;

  ul li {
    list-style: none;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  i {
    position: absolute;
    top: 50%;
    background-color: var(--mainColor);
    box-shadow: 1px 1px 5px 1px rgba(0, 0, 0, 0.25);
    padding: 5px;
    border-top-right-radius: 2px;
    border-bottom-right-radius: 2px;
    font-size: 18px;
    cursor: pointer;
    display: none;
    transition: all 1s;
    color: #fff;
  }
}

@media (max-width: 1010px) {
  [dir="ltr"] {
    ul {
      position: absolute;
      top: 70px;
      left: -230px;
      background-color: #fff;
      box-shadow: 1px 5px 5px 1px rgba(0, 0, 0, 0.25);
      transition: all 1s;
      z-index: 5;
    }
    .side i {
      display: block;
      z-index: 5;
    }
  }
  [dir="rtl"] {
    ul {
      position: absolute;
      top: 70px;
      right: -230px;
      background-color: #fff;
      box-shadow: 1px 5px 5px 1px rgba(0, 0, 0, 0.25);
      transition: all 1s;
      z-index: 5;
    }
    .side i {
      display: block;
      z-index: 5;
    }
  }
}

.content {
  display: flex;
  align-items: center;
  // margin-top: 15px;
  width: 200px;
  height: 40px;
  font-size: 17px;
  color: #010101;
  text-decoration: none;
}

.active {
  display: flex;
  align-items: center;
  // margin-top: 15px;
  border-radius: 8px;
  width: 200px;
  height: 30px;
  background-color: var(--mainColor);
  color: var(--secondaryColor);
  text-decoration: none;
  position: relative;

  &::before {
    content: "";
    width: 50px;
    height: 30px;
    background-color: var(--mainColor);
    position: absolute;
    top: 0;
    border-radius: 8px;
  }
}

.line {
  height: 1px;
  width: 230px;
  background-color: #707070;
  margin-top: 15px;
  margin-bottom: 15px;
}

[dir="rtl"] {
  i {
    right: 0;
  }
  .click {
    position: absolute;
    right: 230px;
    & ~ ul {
      position: absolute;
      top: 70px;
      right: 0px;
    }
  }
  .content {
    padding-right: 10px;
    font-family: "Noto Sans Arabic", sans-serif;
  }
  .active {
    padding-right: 10px;
    font-family: "Noto Sans Arabic", sans-serif;

    &::before {
      right: -55px;
    }
  }
}

[dir="ltr"] {
  i {
    left: 0;
  }
  .click {
    position: absolute;
    left: 230px;
    & ~ ul {
      position: absolute;
      top: 70px;
      left: 0px;
    }
  }
  .content {
    padding-left: 10px;
    font-family: "Open Sans", sans-serif;
  }
  .active {
    padding-left: 10px;
    font-family: "Open Sans", sans-serif;
    &::before {
      left: -67px;
      padding-left: 10px;
    }
  }
}
