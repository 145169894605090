.loading-spinner {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 500px;
  font-size: 50px;
  margin: auto;
  color: var(--mainColor);
}

.form-search {
  position: relative;
  margin-top: 15px;
  .search-by {
    position: absolute;
    top: 50%;
    right: 20px;
    transform: translate(0, -50%);
    display: flex;

    .search-by-input {
      position: relative;
      width: 90px;
      height: 35px;
      background-color: #e3e3e3;
      border: none;
      border-top-right-radius: 7px;
      border-bottom-right-radius: 7px;
      margin-left: -5px;
      cursor: pointer;
      font: {
        family: "Open Sans", sans-serif;
        size: 14px;
      }

      &:focus {
        outline-color: #000;
      }
    }
  }

  .filter-by-container {
    display: flex;
    .filter-by {
      margin-top: 15px;
      margin-right: 15px;
      .search-by-input {
        background-color: #e3e3e3;
        border: none;
        border-radius: 7px;
        padding: 10px 5px;
        cursor: pointer;
        font: {
          family: "Open Sans", sans-serif;
          size: 14px;
        }

        &:focus {
          outline-color: #000;
        }
      }
    }
  }
}

.no-results {
  background-color: #f8d7da;
  font-family: "Open Sans", sans-serif;
  text-align: center;
  margin: 20px;
  font-size: 20px;
  color: #842029;
  padding: 10px;
}

.loading-infinity-scroll {
  text-align: center;
  margin-top: 10px;
  margin-bottom: 10px;
  color: var(--mainColor);
  font-size: 40px;
}

.end-message {
  font-size: 20px;
  text-align: center;
  font-family: "Open Sans", sans-serif;
  margin: 15px 0;
}

.alpha {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;

  button {
    margin-right: 20px;
    margin-top: 10px;
    width: auto;
    height: 35px;
    background-color: var(--mainColor);
    color: var(--secondaryColor);
    border: 1px solid var(--mainColor);
    border-radius: 5px;
    padding: 10px 22px;
    display: flex;
    align-items: center;
    font: {
      family: "Open Sans", sans-serif;
      size: 16px;
    }
    cursor: pointer;
    transition: all 0.4s;

    &:hover {
      background-color: var(--secondaryColor);
      color: var(--mainColor);
      border: 1px solid var(--mainColor);
    }
  }

  .filter-by {
    margin-top: 15px;
    margin-right: 15px;
    .search-by-input {
      background-color: #e3e3e3;
      border: none;
      border-radius: 7px;
      padding: 10px 5px;
      cursor: pointer;
      font: {
        family: "Open Sans", sans-serif;
        size: 14px;
      }

      &:focus {
        outline-color: #000;
      }
    }
  }
}
