.login-admin {
  display: flex;

  .login-admin-left {
    background-color: var(--mainColor);
    height: 100vh;
    width: 50%;
    display: flex;
    justify-content: center;
    align-items: center;

    .admin-login-div {
      position: relative;

      .admin-login-logo {
        width: 261px;
        height: 344px;
      }
    }
  }

  .login-admin-right {
    background-color: var(--secondaryColor);
    height: 100vh;
    width: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;

    .admin-login-box {
      width: 491px;
      height: 318px;
      background-color: var(--secondaryColor);
      border-radius: 15px;
      box-shadow: 0px 0px 9px 0px rgba(0, 0, 0, 0.2);
      display: flex;
      flex-direction: column;
      justify-content: space-evenly;
      align-items: center;

      p {
        color: black;
        font-size: 35px;
        margin-top: 10px;
      }
    }
    .lang {
      position: absolute;
      top: 12%;
      right: 8%;

      button {
        font: {
          family: "Noto Sans Arabic", sans-serif;
          size: 16px;
          weight: bold;
        }
        text-decoration: none;
        color: black;
        transition: all 0.4s;
        letter-spacing: 1px;
        border: none;
        background-color: transparent;
        cursor: pointer;

        &:hover {
          color: var(--mainColor);
        }
      }
    }
  }
}

@media (max-width: 1010px) {
  .login-admin .login-admin-left {
    display: none;
  }
  .login-admin .login-admin-right {
    width: 100vw;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.err {
  color: #842029;
  background-color: #f8d7da;
  border-color: #f5c2c7;
  padding: 0px 10px;
  border-radius: 5px;
  font: {
    size: 14px;
    family: "Noto Sans Arabic", sans-serif;
    weight: bold;
  }
  margin-bottom: 10px;
  text-align: center;
}

[dir="ltr"] {
  .admin-login-box {
    p {
      font-family: "Open Sans", sans-serif;
    }
  }
}

[dir="rtl"] {
  .admin-login-box {
    p {
      font-family: "Noto Sans Arabic", sans-serif;
    }
  }
}
