.sorting {
  .arrange-button {
    margin: 0px 2px;
    background-color: var(--mainColor);
    color: var(--secondaryColor);
    border: 1px solid var(--mainColor);
    padding: 5px;
    border-radius: 10px;
    font: {
      family: "Open Sans", sans-serif;
      size: 14px;
    }
    cursor: pointer;
    transition: all 0.4s;

    &:hover {
      background-color: var(--secondaryColor);
      color: var(--mainColor);
      border: 1px solid var(--mainColor);
    }
  }

  .arrange-button-disabled {
    margin: 0px 2px;
    background-color: gray;
    color: var(--secondaryColor);
    border: 1px solid gray;
    padding: 5px;
    border-radius: 10px;
    font: {
      family: "Open Sans", sans-serif;
      size: 14px;
    }
    cursor: pointer;
    transition: all 0.4s;
  }
}

.table-data-cell:has(> .to-be-sorted) {
  background-color: #dad8d8;
}
