.main-button-admin {
  width: 100%;
  height: 50px;
  background-color: var(--mainColor);
  color: var(--secondaryColor);
  border: 1px solid var(--secondaryColor);
  border-radius: 15px;
  margin: 10px 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  font: {
    family: "Open Sans", sans-serif;
    size: 20px;
  }
  cursor: pointer;
  transition: all 0.4s;

  &:hover {
    background-color: var(--secondaryColor);
    color: var(--mainColor);
    border: 1px solid var(--mainColor);
  }
}
