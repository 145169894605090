.products-container {
  margin: 40px;
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  align-items: center;
  justify-content: center;
  gap: 20px;
  .product-card {
    align-self: center;
    justify-self: center;
  }
}

.product-image-container {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  overflow: hidden;
  border: 1px solid gray;
  .product-image {
    width: 100%;
    object-fit: contain;
    height: 50px;
    border-radius: 50%;
  }
}

.branch-data {
  text-align: center;
  margin: 20px 0;
  font-family: "Open Sans", sans-serif;

  h3 {
    color: var(--mainColor);
    font-size: 25px;
    margin-bottom: 5px;
    text-transform: capitalize;
  }

  img {
    width: 100px;
    margin-right: 10px;
  }

  span {
    color: #707070;
  }

  p {
    font-size: 14px;
  }
}

.check {
  display: flex;
  align-items: center;
  justify-content: center;

  input[type="checkbox"] + label {
    display: block;
    cursor: pointer;
    font-family: "Arial";
  }

  input[type="checkbox"] {
    display: none;
  }

  input[type="checkbox"] + label:before {
    content: "\2714";
    border: 0.1em solid #000;
    border-radius: 0.2em;
    display: inline-block;
    width: 20px;
    height: 20px;
    padding: 0.2em;
    color: transparent;
    transition: 0.2s;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: bold;
    font-size: 20px;
  }

  input[type="checkbox"] + label:active:before {
    transform: scale(0);
  }

  input[type="checkbox"]:checked + label:before {
    border: 0.1em solid var(--mainColor);
    color: var(--mainColor);
    font-weight: bold;
    font-size: 20px;
  }
}

@media screen and (max-width: 1400px) {
  .products-container {
    grid-template-columns: repeat(4, 1fr);
  }
}

@media screen and (max-width: 1150px) {
  .products-container {
    grid-template-columns: repeat(3, 1fr);
  }
}

@media screen and (max-width: 850px) {
  .products-container {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media screen and (max-width: 600px) {
  .products-container {
    grid-template-columns: 1fr;
  }
}
