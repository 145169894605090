.table-row {
  width: 100%;
  text-align: start;
  cursor: pointer;
  &:hover {
    background-color: #dad8d8;
  }
}

.table-data-cell,
td {
  padding: 15px;
  border-top: 1px solid #707070;
  text-align: center;
}
