.large-banner {
  margin-bottom: 5%;
  border-radius: 7px;
  width: 100%;
  height: 250px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;

  .uploaded-img {
    height: 100%;
    width: 80%;
    border-radius: 7px;
    cursor: pointer;
  }

  .remove {
    border: none;
    background-color: var(--mainColor);
    color: var(--secondaryColor);
    border: 1px solid var(--mainColor);
    font-size: 20px;
    border-radius: 8px;
    cursor: pointer;
    padding: 6px 10px;
    transition: all 0.5s;
    position: absolute;
    top: 0;
    right: 0;

    &:hover {
      background-color: transparent;
      border-radius: 8px;
      color: var(--mainColor);
      border: 1px solid var(--mainColor);
    }
  }
}
