.main-chat {
  height: 82vh;
  display: flex;
  margin: 20px;
  box-shadow: 1px 1px 5px 1px rgba(0, 0, 0, 0.51);
  border-radius: 15px;

  .active-chats {
    width: 30%;
    background-color: var(--mainColor);
    border-bottom-left-radius: 15px;
    border-top-left-radius: 15px;
    overflow-y: auto;
    overflow-x: hidden;
    display: flex;
    flex-direction: column;
    align-items: flex-end;

    .unavaliable-chat {
      width: 95%;
      height: 100px;
      display: flex;
      align-items: center;
      margin-top: 10px;
      margin-bottom: 5px;
      cursor: pointer;

      .image {
        width: 30%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        overflow: hidden;
        .icon-avatar {
          background-color: rgba(255, 255, 255, 0.368);
          padding: 15px;
          border-radius: 7px;
        }
        .chat-image {
          width: 100%;
          height: 100%;
          object-fit: contain;
          border-radius: 15px;
          padding: 10px;
        }
      }
      .data {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        height: 70px;
        margin-left: 7px;
        margin-right: 7px;
        .name {
          font-family: "Open Sans", sans-serif;
          font-size: 15px;
          font-weight: 100;
          text-transform: capitalize;

          .last-message {
            p {
              margin-top: 5px;
              font-family: "Open Sans", sans-serif;
              font-size: 13px;
              color: rgb(0, 0, 0);
            }
          }
        }
        .last-updated {
          font-family: "Open Sans", sans-serif;
          font-size: 13px;
          font-weight: 100;
          color: rgb(0, 0, 0);
        }
      }
    }

    .avaliable-chat {
      width: 95%;
      height: 100px;
      display: flex;
      align-items: center;
      margin-top: 10px;
      margin-bottom: 5px;
      cursor: pointer;
      background-color: var(--secondaryColor);
      border-bottom-left-radius: 10px;
      border-top-left-radius: 10px;

      .image {
        width: 30%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        overflow: hidden;
        .icon-avatar {
          background-color: rgba(255, 255, 255, 0.368);
          padding: 15px;
          border-radius: 7px;
        }
        .chat-image {
          width: 100%;
          height: 100%;
          object-fit: contain;
          border-radius: 15px;
          padding: 10px;
        }
      }
      .data {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        height: 70px;
        margin-left: 7px;
        margin-right: 7px;
        .name {
          font-family: "Open Sans", sans-serif;
          font-size: 15px;
          font-weight: 100;
          text-transform: capitalize;

          .last-message {
            p {
              font-family: "Open Sans", sans-serif;
              font-size: 13px;
              color: rgb(0, 0, 0);
              margin-top: 5px;
            }
          }
          .last-updated {
            font-family: "Open Sans", sans-serif;
            font-size: 13px;
            font-weight: 100;
            color: #707070;
          }
        }
      }
    }
  }

  .chat {
    width: 70%;
    border-bottom-right-radius: 15px;
    border-top-right-radius: 15px;
  }

  ::-webkit-scrollbar {
    width: 5px;
    height: 5px;
  }
  ::-webkit-scrollbar-track {
    background: var(--mainColor);
    border-radius: 5px;
  }
  ::-webkit-scrollbar-thumb {
    background: var(--mainColor);
    border-radius: 5px;
  }
  ::-webkit-scrollbar-thumb:hover {
    background: var(--mainColor);
  }
}
