.count {
  margin-top: 15px;
  button {
    width: 180px;
    padding: 10px 22px;
    background-color: var(--mainColor);
    color: var(--secondaryColor);
    border: 1px solid var(--mainColor);
    border-radius: 15px;
    font: {
      family: "Open Sans", sans-serif;
      size: 20px;
    }
    cursor: pointer;
    transition: all 0.4s;

    &:hover {
      background-color: var(--secondaryColor);
      color: var(--mainColor);
      border: 1px solid var(--mainColor);
    }
  }

  a {
    width: 180px;
    padding: 10px 22px;
    background-color: var(--mainColor);
    color: var(--secondaryColor);
    border: 1px solid var(--mainColor);
    border-radius: 15px;
    font: {
      family: "Open Sans", sans-serif;
      size: 20px;
    }
    cursor: pointer;
    transition: all 0.4s;

    &:hover {
      background-color: var(--secondaryColor);
      color: var(--mainColor);
      border: 1px solid var(--mainColor);
    }
  }
}

.chat-with {
  margin-left: 20px;
  width: 50px;
  border: 1px solid var(--mainColor);
  background-color: var(--mainColor);
  color: var(--secondaryColor);
  font-size: 14px;
  cursor: pointer;
  padding: 6px 10px;
  border-radius: 8px;
  transition: all 0.5s;

  &:hover {
    background-color: var(--secondaryColor);
    color: var(--mainColor);
    border: 1px solid var(--mainColor);
  }
}
