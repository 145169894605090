.input-label-disabled {
  display: flex;
  justify-content: space-between;
  align-items: center;
  white-space: nowrap;

  .disabled-label {
    color: var(--mainColor);
    font-size: 15px;
    display: flex;
    justify-content: flex-start;
  }

  .disabled-input {
    width: 600px;
    height: 40px;
    background-color: #f1f1f1;
    border: none;
    border-radius: 15px;
    padding-left: 20px;
    padding-right: 20px;
    margin-bottom: 5px;
    font: {
      family: "Open Sans", sans-serif;
      size: 15px;
    }
    display: inline;
  }
}
