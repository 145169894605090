.nav {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 20;
  grid-area: nav;
  height: 70px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: var(--secondaryColor);
  padding: 0 100px;
  box-shadow: 0px 1px 5px 1px rgba(0, 0, 0, 0.25);
  overflow: hidden;

  .logo {
    width: 50px;
    cursor: pointer;
  }

  button {
    font: {
      family: "Noto Sans Arabic", sans-serif;
      size: 16px;
      weight: bold;
    }
    text-decoration: none;
    color: black;
    transition: all 0.4s;
    letter-spacing: 1px;
    border: none;
    background-color: transparent;
    cursor: pointer;

    &:hover {
      color: var(--mainColor);
    }
  }

  .user-data {
    width: 200px;
    text-align: center;
    font-family: "Open Sans", sans-serif;
    font-size: 16px;

    span {
      color: var(--mainColor);
      cursor: pointer;
    }
  }
}

.bell {
  position: absolute;
  top: 50%;
  right: 5.5%;
  transform: translate(0, -50%);

  i {
    font-size: 20px;
    cursor: pointer;
  }

  .dot {
    width: 15px;
    height: 15px;
    background-color: brown;
    position: absolute;
    top: -35%;
    right: -50%;
    border-radius: 50%;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 11px;
    color: white;
    font-weight: bold;
    padding: 2px;
  }
}

.list {
  position: fixed;
  top: 11%;
  right: 5%;
  width: 350px;
  height: 500px;
  background-color: white;
  box-shadow: 1px 1px 5px 1px rgba(0, 0, 0, 0.51);
  overflow: auto;
  border-radius: 5px;
  z-index: 50;

  .item {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-evenly;
    flex-wrap: wrap;
    padding-top: 10px;
    cursor: pointer;
    transition: all 0.5s;

    .image {
      width: 15%;

      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
        border-radius: 50%;
        overflow: hidden;
      }
    }

    .data {
      display: flex;
      justify-content: space-between;
      width: 75%;

      font: {
        family: "Noto Sans Arabic", sans-serif;
        size: 14px;
        weight: bold;
      }
    }

    .datetime {
      width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      margin-right: 10px;
      font: {
        family: "Noto Sans Arabic", sans-serif;
        size: 12px;
        weight: bold;
      }
    }

    &:hover {
      background-color: rgb(230, 230, 230);
    }
  }
  .line {
    height: 1px;
    width: 100%;
    background-color: rgb(189, 189, 189);
    margin: 0;
    padding: 0;
  }
}
