.loading-spinner {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 500px;
  font-size: 50px;
  margin: auto;
  color: var(--mainColor);
}

.overlay {
  width: 100vw;
  height: 100vh;
  background-color: rgba($color: #000000, $alpha: 0.6);
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: auto;
  z-index: 25;

  .add-admin-box {
    background-color: var(--secondaryColor);
    box-shadow: 1px 1px 5px 1px rgba(0, 0, 0, 0.51);
    text-align: center;
    padding: 20px 40px;
    border-radius: 20px;
    max-width: 100%;

    h3,
    .admin-name,
    span {
      font-family: "Open Sans", sans-serif;
      font-size: 30px;
      font-weight: bold;
      color: var(--mainColor);
      margin-bottom: 15px;
    }
    .admin-name {
      color: black;
    }

    .modal-buttons {
      display: flex;
      justify-content: flex-start;
      width: 100%;
    }

    .main-button-admin,
    .cancel-remove,
    .delete {
      width: 100%;
      height: 50px;
      background-color: var(--secondaryColor);
      color: var(--mainColor);
      border: 1px solid var(--mainColor);
      border-radius: 15px;
      margin: 10px 5px;
      display: flex;
      justify-content: center;
      align-items: center;
      font: {
        family: "Open Sans", sans-serif;
        size: 20px;
      }
      cursor: pointer;
      transition: all 0.4s;

      &:hover {
        background-color: var(--mainColor);
        color: var(--secondaryColor);
        border: 1px solid var(--secondaryColor);
      }
    }
  }

  .view-image {
    background-color: var(--secondaryColor);
    box-shadow: 1px 1px 5px 1px rgba(0, 0, 0, 0.51);
    text-align: center;
    padding: 20px 40px;
    border-radius: 20px;
    max-width: 100%;
    height: 100vh;

    img {
      width: 100%;
      height: 100%;
    }
  }
}

.cancel {
  background-color: var(--mainColor);
  font-size: 20px;
  padding: 5px 10px;
  border-radius: 50%;
  cursor: pointer;
  color: var(--secondaryColor);
}

.overlay-remove-cancel {
  display: none;
}

.index {
  font-family: "Open Sans", sans-serif;
  display: flex;
  justify-content: space-evenly;
  align-items: center;

  i {
    font-size: 16px;
    cursor: pointer;
  }
}

.form-search {
  margin: 0px 20px;

  .form-input-search {
    position: relative;

    .input-search {
      width: 100%;
      height: 40px;
      background-color: #e3e3e3;
      border: none;
      border-radius: 7px;
      padding-left: 60px;
      padding-right: 20px;
      font: {
        size: 16px;
        family: "Open Sans", sans-serif;
      }

      &::placeholder {
        color: #111111;
        font-size: 14px;
      }
    }

    .label-search {
      position: absolute;
      top: 50%;
      left: 0;
      margin-left: 5px;
      transform: translate(0, -50%);
      font: {
        family: "Open Sans", sans-serif;
        weight: 100;
        size: 16px;
      }
      color: #86817c;
      cursor: text;
      transition: all 0.4s;
      display: flex;
      align-items: center;

      .SearchLogo {
        width: 23px;
        margin-left: 20px;
      }
    }
  }
}

[dir="ltr"] {
  .cancel {
    position: absolute;
    top: 25px;
    right: 40px;
  }
}

[dir="rtl"] {
  .cancel {
    position: absolute;
    top: 25px;
    left: 40px;
  }
}
