.mainTable {
  display: grid;
  margin: 20px;
  border-radius: 15px;
  box-shadow: 1px 1px 5px 1px rgba(0, 0, 0, 0.51);
  // width: 100%;
  overflow-x: auto;

  tbody tr {
    text-align: center;
    font-size: 14px;
    color: black;
    cursor: pointer;

    .remove,
    .chat-with,
    .edit {
      border: none;
      background-color: transparent;
      color: var(--mainColor);
      font-size: 14px;
      cursor: pointer;
      padding: 6px 10px;
      transition: all 0.5s;

      &:hover {
        background-color: var(--mainColor);
        border-radius: 8px;
        color: var(--secondaryColor);
      }
    }

    .view {
      border: none;
      background-color: transparent;
      color: var(--mainColor);
      font-size: 14px;
      cursor: pointer;
      padding: 6px 10px;
      transition: all 0.5s;
      text-decoration: none;

      &:hover {
        background-color: var(--mainColor);
        border-radius: 8px;
        color: var(--secondaryColor);
      }
    }

    .details {
      border: none;
      background-color: transparent;
      color: var(--mainColor);
      font-size: 14px;
      cursor: pointer;
      padding: 6px 10px;
      transition: all 0.5s;

      &:hover {
        background-color: var(--mainColor);
        border-radius: 8px;
        color: var(--secondaryColor);
      }
    }
  }
}

@media (max-width: 1010px) {
  .mainTable {
    margin-left: 40px;
    margin-right: 40px;
    overflow-x: scroll;
  }
}

[dir="ltr"] {
  thead tr,
  .total,
  tbody tr,
  .remove,
  .details {
    font-family: "Open Sans", sans-serif;
  }
}

[dir="rtl"] {
  thead tr,
  .total,
  tbody tr,
  .remove,
  .details {
    font: {
      family: "Noto Sans Arabic", sans-serif;
      weight: bold;
    }
  }
}
