.show-password-icon {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
  [fill] {
    fill: var(--mainColor);
  }
}

[dir="rtl"] {
    .show-password-icon {
      left: 20px;
    }
}

[dir="ltr"] {
    .show-password-icon {
      right: 20px;
    }
}