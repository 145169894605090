.orders-card {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  margin-bottom: 10px;
  box-shadow: 1px 1px 5px 1px rgba(0, 0, 0, 0.51);
  border-radius: 10px;
  height: 200px;
  cursor: pointer;

  .data,
  .data-data {
    font-family: "Open Sans", sans-serif;
    width: 100%;
    padding: 30px;
    display: flex;
    justify-content: space-between;

    h3 {
      color: #919090;
    }
    p {
      padding: 3px;
      color: #919090;
    }
    span {
      color: var(--mainColor);
    }
  }
}

.orders-card-full {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  box-shadow: 1px 1px 5px 1px rgba(0, 0, 0, 0.51);
  border-radius: 10px;
  height: 250px;
  width: 100%;
  cursor: pointer;

  .image {
    width: 30%;
    object-fit: contain;

    img {
      width: 100%;
      height: 150px;
      object-fit: contain;
    }
  }

  .order {
    font-family: "Open Sans", sans-serif;
    width: 70%;
    padding: 30px;

    h3 {
      color: #919090;
    }
    p {
      padding: 3px;
      color: #919090;
    }
    span {
      color: var(--mainColor);
    }
  }
}
