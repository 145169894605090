.add-client {
  position: relative;
  margin: 20px;
  border-radius: 15px;
  box-shadow: 1px 1px 5px 1px rgba(0, 0, 0, 0.51);
  padding: 20px 100px;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: auto auto;
  grid-template-areas: "header header header" "image inputs inputs";
  column-gap: 30px;

  .header {
    grid-area: header;
  }
  h3 {
    font-family: "Open Sans", sans-serif;
    font-size: 35px;
    font-weight: bold;
    color: var(--mainColor);
    margin-bottom: 15px;
    padding: 20px;
    text-align: center;
  }

  .client-image {
    grid-area: image;
  }

  .generate {
    font-size: 24px;
    display: flex;

    button {
      background-color: var(--mainColor);
      margin-top: 10px;
      width: 250px;
      border: 2px solid var(--secondaryColor);
      border-radius: 10px;
      height: 50px;
      cursor: pointer;
      display: flex;
      justify-content: space-evenly;
      align-items: center;
      transition: all 0.4s;
      color: var(--secondaryColor);
      font-size: 24px;

      &:hover {
        background-color: var(--secondaryColor);
        color: var(--mainColor);
        border: 1px solid var(--mainColor);
      }
    }
  }

  .form-grid {
    width: 100%;
    grid-area: inputs;

    .input-grid {
      display: grid;
      gap: 5px;
      display: flex;
      flex-direction: column;

      .main-input {
        width: 100%;
        margin: 10px;
      }
    }

    .generate-barcode {
      display: flex;
      align-items: center;
      justify-content: space-evenly;

      .main-image-label.barcode {
        height: 75px;
      }
    }
  }

  .button-grid {
    display: flex;
    padding: 0 10px;
    padding-bottom: 20px;

    .main-button-admin {
      height: 50px;
      background-color: var(--mainColor);
      color: var(--secondaryColor);
      border: 2px solid var(--secondaryColor);
      border-radius: 15px;
      margin: 10px 0;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: row;
      font: {
        family: "Open Sans", sans-serif;
        size: 20px;
      }
      cursor: pointer;
      transition: all 0.4s;

      &:hover {
        background-color: var(--secondaryColor);
        color: var(--mainColor);
        border: 1px solid var(--mainColor);
      }
    }
  }
}

@media screen and (min-width: 801px) {
  .form-grid {
    .input-grid {
      grid-template-columns: repeat(3, 1fr);
    }
  }
  .button-grid {
    justify-content: flex-end;

    .main-button-admin {
      width: 150px;
    }
  }
}

@media screen and (max-width: 800px) {
  .form-grid {
    .input-grid {
      grid-template-columns: repeat(2, 1fr);
    }
  }
  .button-grid {
    justify-content: flex-end;

    .main-button-admin {
      width: 150px;
    }
  }
}

@media screen and (max-width: 600px) {
  .form-grid {
    .input-grid {
      grid-template-columns: repeat(1, 1fr);
    }
  }
  .button-grid {
    justify-content: center;

    .main-button-admin {
      width: 50%;
    }
  }
}
