.main-service-details {
  position: relative;
  margin: 20px;
  box-shadow: 1px 1px 5px 1px rgba(0, 0, 0, 0.51);
  border-radius: 15px;
  display: flex;
  justify-content: space-evenly;
  align-items: flex-start;
  // background-color: aqua;
  // height: 80vh;
}
