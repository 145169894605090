.add-notification {
  font-family: "Open Sans", sans-serif;
  padding: 10px 20px;
  display: flex;
  justify-content: flex-end;
  width: 100%;

  .main-button-add {
    width: fit-content;
    padding: 10px 22px;
    background-color: var(--mainColor);
    color: var(--secondaryColor);
    border: 1px solid var(--mainColor);
    border-radius: 8px;
    font: {
      family: "Open Sans", sans-serif;
      size: 16px;
    }
    cursor: pointer;
    transition: all 0.4s;

    &:hover {
      background-color: var(--secondaryColor);
      color: var(--mainColor);
      border: 1px solid var(--mainColor);
    }
  }
}
