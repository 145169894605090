.image-upload-container {
  width: 350px;
  margin: 20px 0;
  position: relative;
  .upload-img-btn {
    height: 100px;
    width: 350px;
    background-color: #f1f1f1;
    border: none;
    border-radius: 25px;
    padding: 0 20px;
    margin: 0;
    font-size: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    gap: 60px;
    &.view-only {
      cursor: not-allowed;
    }
    &:disabled {
      cursor: not-allowed;
    }
    .upload-icon {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }

  .image-upload-label {
    top: 0;
    position: absolute;
    left: 20px;
    background-color: var(--mainColor);
    color: var(--secondaryColor);
    font-size: 13px;
    padding: 0 10px;
    border-radius: 20px;
    font-weight: 100;
    transform: translate(0, -50%);
  }

  .images-list {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    width: 350px;
    gap: 5px;

    .image-item-container {
      position: relative;
      height: 80px;
      width: 80px;
      cursor: grab;

      &.view-only {
        cursor: pointer;
      }

      .image-item {
        height: 80px;
        width: 80px;
      }

      .image-item-selected {
        height: 80px;
        width: 80px;
        border: 2px solid var(--mainColor);
      }

      .delete-img-icon {
        position: absolute;
        top: 0px;
        right: 0px;
        width: 20px;
        height: 20px;
        background: #ff3e3e;
        color: #fff;
        font-size: 10px;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        z-index: 10;
      }
      .rotate-img-icon {
        position: absolute;
        top: 0px;
        left: 0px;
        width: 20px;
        height: 20px;
        background: #34f938;
        color: #fff;
        font-size: 10px;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        z-index: 10;
      }
    }
  }

  .dragged-image {
    background-color: var(--mainColor);
    position: relative;
    height: 80px;
    width: 80px;
    img {
      height: 80px;
      width: 80px;
    }
  }
}

.dragged-image {
  position: relative;
  height: 80px;
  width: 80px;
  border: 1px solid var(--mainColor);
  background-color: var(--mainColor);
  .image-item {
    height: 80px;
    width: 80px;
  }
  .delete-img-icon {
    position: absolute;
    top: 0px;
    right: 0px;
    width: 20px;
    height: 20px;
    background: #ff3e3e;
    color: #fff;
    font-size: 10px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    z-index: 10;
  }
  .rotate-img-icon {
    position: absolute;
    top: 0px;
    left: 0px;
    width: 20px;
    height: 20px;
    background: #34f938;
    color: #fff;
    font-size: 10px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    z-index: 10;
  }
}
