.faqs-details {
  margin: 20px;
  box-shadow: 1px 1px 5px 1px rgba(0, 0, 0, 0.51);
  border-radius: 15px;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;

  .client-datails {
    margin-top: 20px;
    height: auto;
    padding-bottom: 20px;

    .data {
      width: 100%;
      height: 100%;
      border-radius: 30px;
      padding: 20px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      font-family: "Open Sans", sans-serif;
      font-size: 18px;

      h2 {
        text-align: center;
        text-transform: uppercase;
        color: var(--mainColor);
        margin-bottom: 5px;
      }

      h3 {
        color: var(--mainColor);

        span {
          color: #919090;
        }
      }
    }
  }

  .back {
    position: absolute;
    i {
      font-size: 30px;
      color: var(--mainColor);
      cursor: pointer;
    }
  }
}
