.reviews-card {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  box-shadow: 1px 1px 5px 1px rgba(0, 0, 0, 0.51);
  border-radius: 10px;

  .image {
    width: 15%;
    object-fit: contain;

    img {
      width: 100%;
      height: 150px;
      object-fit: contain;
    }
  }

  .data {
    font-family: "Open Sans", sans-serif;
    width: 75%;
    padding: 30px;

    h3 {
      color: black;
    }
    p {
      padding: 5px 0;
      color: black;
    }
    span {
      color: var(--mainColor);
    }

    .by {
      cursor: pointer;
    }
  }

  .delete {
    width: 10%;
    display: flex;
    justify-content: flex-end;
    align-items: flex-start;
    height: 150px;
    padding-top: 10px;

    .remove {
      color: var(--mainColor);
      cursor: pointer;
      background-color: transparent;
      border: none;
      font-size: 20px;
    }
  }
}

[dir="ltr"] {
  .delete {
    padding-right: 10px;
    .remove {
      margin-right: 10px;
    }
  }
}

[dir="rtl"] {
  .delete {
    padding-left: 10px;
    .remove {
      margin-left: 10px;
    }
  }
}
