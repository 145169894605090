.category-details {
  position: relative;
  // display: grid;
  // grid-template-columns: repeat(12, 1fr);
  // grid-template-rows: auto auto auto auto auto;
  // grid-template-areas: "image image image data data data data data data data data data" "user user user user user user user user user user user user" "add add add add add add add add add add add add" "card card card card card card card card card card card card" "wishlist wishlist wishlist wishlist wishlist wishlist wishlist wishlist wishlist wishlist wishlist wishlist";
  margin: 20px;
  box-shadow: 1px 1px 5px 1px rgba(0, 0, 0, 0.51);
  border-radius: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 100%;

  .back {
    position: absolute;
    i {
      font-size: 30px;
      color: var(--mainColor);
      cursor: pointer;
    }
  }

  .client-image.vendor-image-details {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 10px;
    min-width: 250px;
    min-height: 250px;
    border-radius: 7px;
    border: 1px solid #707070;
    position: relative;
    overflow: hidden;
    object-fit: cover;
    top: 10%;

    img {
      height: 100%;
      border-radius: 7px;
      overflow: hidden;
      padding: 5px;
      object-fit: cover;
    }

    .delete-image {
      position: absolute;
      top: 0;
      left: 0;
      width: 35px;
      height: 35px;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      background-color: var(--mainColor);
      display: none;

      i {
        color: var(--secondaryColor);
        background-color: transparent;
        border: none;
        font-size: 20px;
      }
    }
  }

  .client-image.subscription-image-details {
    grid-area: image;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 10px;
    width: 250px;
    height: 250px;
    border-radius: 7px;
    border: 1px solid #707070;
    position: relative;
    overflow: hidden;
    object-fit: cover;
    top: 10%;
    cursor: zoom-in;

    img {
      height: 100%;
      border-radius: 7px;
      overflow: hidden;
      padding: 5px;
      object-fit: cover;
    }

    .delete-image {
      position: absolute;
      top: 0;
      left: 0;
      width: 35px;
      height: 35px;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      background-color: var(--mainColor);
      display: none;

      i {
        color: var(--secondaryColor);
        background-color: transparent;
        border: none;
        font-size: 20px;
      }
    }
  }

  .client-image.vendor-image-details:hover .delete-image {
    display: flex;
  }

  .client-datails {
    margin: 12px 0;
    height: auto;
    padding-bottom: 20px;
    width: 70%;

    .data {
      padding: 20px;
      font-family: "Open Sans", sans-serif;
      font-size: 18px;
      // background-color: aqua;

      .toggler {
        width: 235px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin: 8px 0;

        .word {
          font-family: "Open Sans", sans-serif;
          color: var(--mainColor);
          margin-right: 10px;
          font-size: 15px;
        }
      }

      /* The switch - the box around the slider */
      .switch {
        position: relative;
        display: inline-block;
        width: 50px;
        height: 25px;
      }

      /* Hide default HTML checkbox */
      .switch input {
        opacity: 0;
        width: 0;
        height: 0;
      }

      /* The slider */
      .slider {
        position: absolute;
        cursor: pointer;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: #ccc;
        -webkit-transition: 0.4s;
        transition: 0.4s;
      }

      .slider:before {
        position: absolute;
        content: "";
        height: 15px;
        width: 15px;
        left: 8px;
        bottom: 5px;
        background-color: white;
        -webkit-transition: 0.4s;
        transition: 0.4s;
      }

      input:checked + .slider {
        background-color: var(--mainColor);
      }

      input:focus + .slider {
        box-shadow: 0 0 1px var(--mainColor);
      }

      input:checked + .slider:before {
        -webkit-transform: translateX(26px);
        -ms-transform: translateX(26px);
        transform: translateX(20px);
      }

      /* Rounded sliders */
      .slider.round {
        border-radius: 34px;
      }

      .slider.round:before {
        border-radius: 50%;
      }
    }

    .actions {
      display: flex;
      justify-content: flex-end;
      // margin: 10px;

      .approve {
        width: 100px;
        height: 40px;
        background-color: var(--mainColor);
        color: var(--secondaryColor);
        border: 1px solid var(--mainColor);
        border-radius: 7px;
        margin-right: 10px;
        font: {
          family: "Open Sans", sans-serif;
          size: 18px;
        }
        cursor: pointer;
        transition: all 0.4s;

        &:hover {
          background-color: var(--secondaryColor);
          color: var(--mainColor);
          border: 1px solid var(--mainColor);
        }
      }
      .decline {
        width: 100px;
        height: 40px;
        background-color: #58151c;
        color: var(--secondaryColor);
        border: none;
        border-radius: 7px;
        margin-right: 18px;
        font: {
          family: "Open Sans", sans-serif;
          size: 18px;
        }
        cursor: pointer;
        transition: all 0.4s;

        &:hover {
          background-color: var(--secondaryColor);
          color: #58151c;
          border: 1px solid #58151c;
        }
      }
    }
  }
}

@media (max-width: 1000px) {
  .category-details {
    // display: grid;
    // grid-template-columns: repeat(1, 1fr);
    // grid-template-rows: auto auto auto auto;
    // grid-template-areas: "image" "data" "user" "card";
    margin: 20px;
    display: flex;
    flex-direction: column;
    // .client-image {
    //   grid-area: image;
    //   margin-bottom: 80px;
    // }
    // .client-datails {
    //   grid-area: data;
    // }
    // .prev {
    //   grid-area: user;
    // }
  }
}

// [dir="ltr"] {
//   .back {
//     top: 12px;
//     left: 15px;
//   }
// }

// [dir="rtl"] {
//   .back {
//     top: 12px;
//     left: 15px;
//   }
// }
