.main-input-select {
  position: relative;
  display: flex;

  .main-input {
    position: relative;
    width: 340px;
    height: 50px;
    background-color: #f1f1f1;
    border: none;
    border-radius: 15px;
    padding-left: 20px;
    padding-right: 20px;
    margin: 10px 10px;
    cursor: pointer;
    font: {
      family: "Open Sans", sans-serif;
      size: 16px;
    }

    &:focus {
      outline-color: var(--mainColor);
    }

    &:focus ~ .main-label,
    &:not(:placeholder-shown).main-input:not(:focus) ~ .main-label {
      top: 0;
      background-color: var(--mainColor);
      color: var(--secondaryColor);
      font: {
        size: 13px;
        weight: 100;
      }
      padding: 2px 10px 3px;
      border-radius: 10px;
    }
  }

  .main-label {
    position: absolute;
    top: 50%;
    left: 0;
    margin-left: 20px;
    transform: translate(0, -50%);
    color: #86817c;
    cursor: text;
    transition: all 0.4s;
    font: {
      family: "Open Sans", sans-serif;
      size: 16px;
      weight: 100;
    }
  }
}

.multiselect-container {
  width: 100%;
  margin-bottom: 20px;
  margin-top: 15px;
}

.search-wrapper {
  width: 100%;
  height: 50px;
  background-color: #f1f1f1;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  font-family: "Open Sans", sans-serif;

  input {
    margin-left: 15px;
    font-family: "Open Sans", sans-serif;
    font-size: 15px;
    color: black;
  }

  input::placeholder {
    font-family: "Open Sans", sans-serif;
    color: black;
    font-size: 15px;
  }

  .chip {
    background-color: var(--mainColor);
    width: fit-content;
    display: flex;
    flex-direction: row;
    font-family: "Open Sans", sans-serif;
  }
}
.optionListContainer {
  width: 100%;
  font-family: "Open Sans", sans-serif;

  .option {
    background-color: #f1f1f1;
    color: #242424;

    &:hover {
      background-color: var(--mainColor);
    }
  }
}
.highlightOption {
  background-color: #f1f1f1;
  color: #242424;
}

.select-items {
  margin-top: 10px;
  margin-bottom: 0;
}
