.edit-admin {
  background-color: var(--secondaryColor);
  box-shadow: 1px 1px 5px 1px rgba(0, 0, 0, 0.51);
  text-align: center;
  padding: 20px 40px;
  border-radius: 20px;
  max-width: 100%;
  margin: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  h2 {
    text-align: center;
    text-transform: uppercase;
    color: var(--mainColor);
    margin-bottom: 25px;
    font-family: "Open Sans", sans-serif;
    font-size: 25px;
  }
  form {
    button {
      margin-top: 10px;
      width: 360px;
      height: 50px;
      background-color: var(--mainColor);
      color: var(--secondaryColor);
      border: none;
      border-radius: 15px;
      font: {
        family: "Open Sans", sans-serif;
        size: 20px;
      }
      cursor: pointer;
      transition: all 0.4s;

      &:hover {
        background-color: var(--secondaryColor);
        color: var(--mainColor);
        border: 1px solid var(--mainColor);
      }
    }
  }
}

.main-image-label.admin-image {
  margin-top: 15px;
  border: 1px solid #707070;
  width: 250px;
  height: 250px;
  border-radius: 50%;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;

  .uploaded-img {
    height: 100%;
    object-fit: contain;
    border-radius: 7px;
  }
  .main-input-image {
    display: none;
  }
  .main-label-image {
    z-index: 3;
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
    font-size: 20px;
    color: var(--secondaryColor);
    align-items: center;
    justify-content: center;
    cursor: pointer;
    background-color: #00000040;
    border-radius: 7px;
  }
}

.change-password {
  position: relative;
  margin: 20px;
  border-radius: 15px;
  box-shadow: 1px 1px 5px 1px rgba(0, 0, 0, 0.51);
  padding: 20px 100px;

  .header {
    h3 {
      font-family: "Open Sans", sans-serif;
      font-size: 30px;
      font-weight: bold;
      color: var(--mainColor);
      margin-bottom: 15px;
      padding: 20px;
      text-align: center;
    }
  }

  .form-grid {
    width: 100%;

    .input-grid {
      display: flex;
      flex-direction: column;

      .main-input {
        width: 100%;
        margin: 10px;
      }
    }
  }

  .button-grid {
    display: flex;
    padding: 0 10px;
    padding-bottom: 20px;

    .main-button-admin {
      height: 50px;
      background-color: var(--mainColor);
      color: var(--secondaryColor);
      border: 2px solid var(--secondaryColor);
      border-radius: 15px;
      margin: 10px 0;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: row;
      font: {
        family: "Open Sans", sans-serif;
        size: 20px;
      }
      cursor: pointer;
      transition: all 0.4s;

      &:hover {
        background-color: var(--secondaryColor);
        color: var(--mainColor);
        border: 1px solid var(--mainColor);
      }
    }
  }
}
