.filter {
  background-color: var(--secondaryColor);
  margin: 10px 20px;
  flex-direction: row;

  input {
    display: none;
    &:checked ~ label {
      background-color: var(--mainColor);
      color: var(--secondaryColor);
    }
  }

  .items {
    display: flex;
    label {
      padding: 5px 10px;
      background-color: #e3e3e3;
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 5px;
      border-radius: 5px;
      font-family: "Open Sans", sans-serif;
      color: #000;
      font-size: 14px;
      cursor: pointer;
    }

    .clicked {
      background-color: aqua !important;
    }

    .alpha {
      width: 100%;
      display: flex;
      justify-content: flex-end;
      align-items: center;

      button {
        width: auto;
        height: 35px;
        background-color: var(--mainColor);
        color: var(--secondaryColor);
        border: 1px solid var(--mainColor);
        border-radius: 5px;
        padding: 10px 22px;
        display: flex;
        align-items: center;
        font: {
          family: "Open Sans", sans-serif;
          size: 16px;
        }
        cursor: pointer;
        transition: all 0.4s;

        &:hover {
          background-color: var(--secondaryColor);
          color: var(--mainColor);
          border: 1px solid var(--mainColor);
        }
      }

      .filter-by {
        margin-top: 15px;
        margin-right: 15px;
        .search-by-input {
          background-color: #e3e3e3;
          border: none;
          border-radius: 7px;
          padding: 10px 5px;
          cursor: pointer;
          font: {
            family: "Open Sans", sans-serif;
            size: 14px;
          }

          &:focus {
            outline-color: #000;
          }
        }
      }
    }
  }
}
