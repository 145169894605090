.table-header {
  .table-header-data {
    .permission-icon {
      z-index: 3000;
      cursor: pointer;
      margin: 0 5px;
      &:hover {
        transform: scale(1.1);
      }
    }
  }
}
