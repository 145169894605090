.new-branch-card {
  width: 320px;
  height: 150px;
  background-color: #f1f1f1;
  border: 2px solid #e2e2e2;
  display: flex;
  align-items: center;
  border-radius: 7px;

  .branch-image {
    width: 120px;
    height: 150px;
    background-color: #f1f1f1;
    border-radius: 7px;
    cursor: pointer;
    border: 2px solid #e2e2e2;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-radius: 7px;
    }
  }

  .branch-data {
    width: 200px;
    height: 150px;
    display: flex;
    padding: 5px;

    .data {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      width: 250px;

      .branch-name {
        font-size: 18px;
        color: var(--mainColor);
        font-family: "Open Sans", sans-serif;
        text-transform: capitalize;
      }

      .manager-name {
        font-size: 15px;
        font-family: "Open Sans", sans-serif;
        text-transform: capitalize;
      }
      .rate {
        display: flex;
        align-items: center;
        gap: 5px;
        font-size: 14px;
        font-family: "Open Sans", sans-serif;
        .rate-icon {
          width: 15px;
          height: 15px;
        }
      }
    }

    .actions {
      .remove {
        color: var(--mainColor);
        cursor: pointer;
        background-color: transparent;
        border: none;
      }
      .details {
        color: var(--mainColor);
        cursor: pointer;
        background-color: transparent;
        border: none;
      }
    }
  }
}
